<template>
  <v-app>
    <loading :active.sync="is_call_api"></loading>
    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254">
        Quản lí các Sub Category {{ category_title }}
      </h1>
    </div>

    <div class="pt-5 pl-5 pr-5">
      <v-btn
        rounded
        color="primary"
        class="mb-5"
        x-small
        large
        @click="openDiaLogSubCategory"
      >
        <v-icon color="white" dark>mdi-plus</v-icon
        ><span style="color: white">Thêm sub category</span>
      </v-btn>
      <v-btn
        rounded
        color="cyan"
        class="mb-5 ml-5"
        x-small
        large
        @click="openDialogSort"
      >
        <v-icon color="white" dark>mdi-sort</v-icon
        ><span style="color: white">Sắp xếp lại các sub category</span>
      </v-btn>
      <div>
        <v-row>
          <v-col cols="12">
            <div class="table-responsive">
              <table
                class="table datatable-bordered table-head-custom table-vertical-center table-head-bg"
              >
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Display Name</th>
                    <th scope="col">Total Programme</th>
                    <th scope="col">Hiện thị home</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(sub_category, i) in product_sub_categories">
                    <tr :key="i">
                      <td scope="row">{{ i + 1 }}</td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ sub_category.display_name }}
                        </p>
                      </td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ sub_category.programmes.length }}
                        </p>
                      </td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg light-blue--text text--darken-1"
                          v-if="sub_category.is_apply_home === 1"
                        >
                          Có
                        </p>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg yellow--text text--darken-2"
                          v-if="sub_category.is_apply_home === 0"
                        >
                          Không
                        </p>
                      </td>
                      <td>
                        <div style="display: flex">
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-success btn-sm"
                                v-bind="attrs"
                                v-on="on"
                                @click="editSubCategory(i)"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color=""
                                    >mdi-square-edit-outline</v-icon
                                  >
                                </span>
                              </button>
                            </template>
                            <span>Edit</span>
                          </v-tooltip>
                          <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-danger btn-sm ml-4"
                                v-bind="attrs"
                                v-on="on"
                                @click="deleteSubCategory(sub_category.id)"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color="">mdi-delete</v-icon>
                                </span>
                              </button>
                            </template>
                            <span>Delete</span>
                          </v-tooltip>
                        </div>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>

    <v-row justify="center">
      <v-dialog
        v-model="dialogSubCategory"
        max-width="1400px"
        scrollable
        persistent
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline" v-if="type_form === 'add'"
                >Add Product Sub Category</span
              >
              <span class="headline" v-else>Edit Sub Product Category</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogSubCategory = false" right
                >mdi-close</v-icon
              >
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="2" class="p-0 m-0"></v-col>
                    <v-col cols="8">
                      <v-radio-group
                        v-model="language"
                        row
                        class="mb-0 mt-0 pb-0"
                        dense
                      >
                        <v-radio label="Việt Nam" value="vi"></v-radio>
                        <v-radio label="English" value="en"></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Tên sub category:</label></v-col
                    >
                    <v-col
                      cols="8"
                      class="p-0 m-0 ml-4"
                      v-if="language === 'vi'"
                      ><v-text-field
                        dense
                        v-model="name_input.vi"
                        filled
                      ></v-text-field
                    ></v-col>
                    <v-col
                      cols="8"
                      class="p-0 m-0 ml-4"
                      v-if="language === 'en'"
                      ><v-text-field
                        dense
                        v-model="name_input.en"
                        filled
                      ></v-text-field
                    ></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Slug:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4"
                      ><v-text-field
                        dense
                        v-model="slug_name_input"
                        filled
                      ></v-text-field
                    ></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Title:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4"
                      ><v-text-field
                        dense
                        v-model="title_input"
                        filled
                      ></v-text-field
                    ></v-col>
                  </v-row>
                  <v-row class="">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Hiện thị trên home:</label></v-col
                    >
                    <v-col cols="8" class="ml-4 p-0">
                      <v-switch
                        class="mt-0 pt-0 ml-2"
                        v-model="is_apply_home"
                        :label="is_apply_home === true ? 'Yes' : 'No'"
                      ></v-switch>
                    </v-col>
                  </v-row>
                  <v-row class="mt-4">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6"
                        >Áp dụng các programme:</label
                      ></v-col
                    >
                    <v-col cols="8" class="ml-4 p-0">
                      <v-autocomplete
                        v-model="programme_ids"
                        :items="programmes"
                        item-value="id"
                        item-text="name.vi"
                        dense
                        filled
                        multiple
                        chips
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form === 'add'"
              @click="btnAddOrEditSubCategory(1)"
            >
              Add
            </button>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form === 'update'"
              @click="btnAddOrEditSubCategory(2)"
            >
              Update
            </button>
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="dialogSubCategory = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <v-dialog v-model="dialogSort" scrollable persistent max-width="600px">
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">Sắp xếp lại ý kiến , nhận xét</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogSort = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <draggable :list="data_draggable">
                    <ul
                      class="list-unstyled drag-css"
                      v-for="(sub_category, i) in data_draggable"
                      :key="i"
                    >
                      <b-media tag="li">
                        <h5 class="mt-0 mb-1" style="color: #3699ff">
                          {{ sub_category.display_name }}
                        </h5>
                      </b-media>
                    </ul>
                  </draggable>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" outlined text @click="sortSubCategory"
              >Update</v-btn
            >
            <v-btn color="red darken-1" text @click="dialogSort = false"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-app>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";
import Swal from "sweetalert2";
import draggable from "vuedraggable";

export default {
  name: "SubCategoryList",
  components: {
    draggable,
    Loading: () => import("vue-loading-overlay"),
  },
  data() {
    return {
      is_call_api: false,
      category_title: "",
      dialogSubCategory: false,
      product_sub_categories: [],
      type_form: null,
      programme_ids: null,
      programmes: [],
      name_input: {
        en: "",
        vi: "",
      },
      sub_category_id: null,
      language: "vi",
      is_apply_home: false,
      offset_input: null,
      dialogSort: false,
      data_draggable: [],
      slug_name_input: "",
      title_input: "",
    };
  },
  computed: {
    category_id: function () {
      return this.$route.params.id;
    },
  },
  created() {
    this.getAllSubCategory();
    this.getProgrammeLimit();
  },
  methods: {
    async getAllSubCategory() {
      let vm = this;
      try {
        vm.is_call_api = true;
        let res = await ApiService.get(
          "prep-app/product-category/" + this.category_id + "/sub"
        );
        if (res.status === 200) {
          vm.category_title = res.data.category;
          vm.product_sub_categories = res.data.data;
          vm.is_call_api = false;
        }
      } catch (error) {
        vm.is_call_api = false;
        console.log(error);
      }
    },
    getProgrammeLimit() {
      let vm = this;
      ApiService.get("prep-app/programme/limit")
        .then(function (res) {
          if (res.status === 200) {
            vm.programmes = res.data;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    openDiaLogSubCategory() {
      this.type_form = "add";
      this.name_input = {
        vi: "",
        en: "",
      };
      this.language = "vi";
      this.programme_ids = [];
      this.is_apply_home = false;
      this.offset_input = this.product_sub_categories.length + 1;
      this.slug_name_input = "";
      this.title_input = "";
      this.dialogSubCategory = true;
    },
    editSubCategory(index) {
      let data = this.product_sub_categories[index];
      this.language = "vi";
      this.name_input = JSON.parse(data.name);
      this.programme_ids = data.programmes.map(function (item) {
        return item.id;
      });
      this.sub_category_id = data.id;
      this.offset_input = data.offset;
      this.is_apply_home = data.is_apply_home === 1;
      this.slug_name_input = data.slug_name;
      this.title_input = data.title;
      this.type_form = "update";
      this.dialogSubCategory = true;
    },
    async btnAddOrEditSubCategory(type) {
      let vm = this;
      let data = {
        name: JSON.stringify(this.name_input),
        programme_ids: this.programme_ids,
        offset: this.offset_input,
        is_apply_home: this.is_apply_home ? 1 : 0,
      };
      if (this.slug_name_input !== "" && this.slug_name_input != null) {
        data.slug_name = this.slug_name_input;
      }
      if (this.title_input !== "" && this.title_input != null) {
        data.title = this.title_input;
      }
      if (type === 1) {
        await ApiService.post(
          "prep-app/product-category/" + this.category_id + "/sub",
          data
        )
          .then(function (res) {
            if (res.status === 200) {
              vm.getAllSubCategory();
              vm.$toasted.success("Tạo thành công !", {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              });
              vm.dialogSubCategory = false;
            }
          })
          .catch(function (error) {
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      } else {
        await ApiService.put(
          "prep-app/product-category/" +
            this.category_id +
            "/sub/" +
            this.sub_category_id,
          data
        )
          .then(function (res) {
            if (res.status === 200) {
              vm.getAllSubCategory();
              vm.$toasted.success("Sửa thành công !", {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              });
              vm.dialogSubCategory = false;
            }
          })
          .catch(function (error) {
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      }
    },
    showError(data_error) {
      for (let i = 0; i < data_error.length; i++) {
        this.$toasted.error(data_error[i] + " !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
      }
    },
    deleteSubCategory(sub_category_id) {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          ApiService.delete(
            "prep-app/product-category/" +
              this.category_id +
              "/sub/" +
              sub_category_id
          )
            .then(function (res) {
              if (res.status === 200) {
                vm.getAllSubCategory();
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },
    openDialogSort() {
      this.data_draggable = JSON.parse(
        JSON.stringify(this.product_sub_categories)
      );
      this.dialogSort = true;
    },
    sortSubCategory() {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want save?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          let items = this.data_draggable.map((item, index) => {
            return {
              sub_category_id: item.id,
              offset: index + 1,
            };
          });
          let data = {
            data_sort: items,
          };
          vm.is_call_api = true;
          ApiService.post(
            "prep-app/product-category/" + this.category_id + "/sub/sort",
            data
          )
            .then(function (res) {
              if (res.status === 200) {
                vm.getAllSubCategory();
                vm.$toasted.success("Sắp xếp thành công !", {
                  theme: "toasted-primary",
                  position: "top-right",
                  duration: 4000,
                });
                vm.is_call_api = false;
                vm.dialogSort = false;
              }
            })
            .catch(function (error) {
              vm.is_call_api = false;
              console.log(error);
            });
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
</style>
